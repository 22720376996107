import React, { useState } from "react";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Box,
  HStack,
  VStack,
} from "@chakra-ui/react";
dayjs.extend(customParseFormat);

const Participants = ({ setDetails, activity, details }) => {
  const { slots, list } = details.participants;

  const handleChange = (e) => {
    setDetails({
      ...details,
      participants: {
        ...details.participants,
        [e.target.name]: e.target.value,
      },
      schedule: {
        startDate: "",
        endDate: "",
        dateArray: [],
      },
    });
  };

  function importExcel(event) {
    const input = event.target;
    const reader = new FileReader();

    reader.onload = function () {
      const fileData = reader.result;
      const workbook = XLSX.read(fileData, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      if (data.length > 1) {
        const processedData = data
          .map((row, i) => {
            if (i > 9) {
              if (row[2] === undefined) return null;
              let bdate = dayjs().format("YYYY-MM-DD");
              if (row[6] && `${row[6]}`.match(/\d{2}\/\d{2}\/\d{4}/)) {
                console.log("data", row[6]);
                bdate = dayjs(`${row[6]}`, "DD/MM/YYYY").format("YYYY-MM-DD");
              } else if (row[6]) {
                const tdate = new Date(Date.UTC(0, 0, row[6] - 1));
                bdate = dayjs(tdate).format("YYYY-MM-DD");
                console.log("row[6]", row[6], bdate);
              }

              return {
                name: row[2],
                age: bdate,
                nif: row[9],
                // comment: row[2],
              };
            }
            return null;
          })
          .filter((row) => row !== null);

        setDetails({
          ...details,
          participants: {
            slots: processedData.length,
            list: processedData,
          },
          schedule: {
            startDate: "",
            endDate: "",
            dateArray: [],
          },
        });
      }
    };

    reader.readAsBinaryString(input.files[0]);
  }

  const updateParticipants = (index, name, value) => {
    setDetails({
      ...details,
      participants: {
        ...details.participants,
        list: participantsAmount.map((p, a) => {
          if (a === index) {
            return { ...details.participants.list[a], [name]: value };
          } else {
            return details.participants.list[a];
          }
        }),
      },
      schedule: {
        startDate: "",
        endDate: "",
        dateArray: [],
      },
    });
  };

  const participantsAmount = Array.from(Array(Number(slots || 0)).keys());

  return (
    <Box width={"100%"}>
      <VStack>
        <Box width={"100%"}>
          <label>
            Participantes (max. 120,
            {activity.minSlots && activity.minSlots !== ""
              ? ` min. ${activity.minSlots}`
              : ""}
            ) <span>*</span>
          </label>
          <Input
            name={"slots"}
            min={0}
            type={"number"}
            value={slots}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              if (Number(e.target.value) <= 120) return handleChange(e);
            }}
            placeholder="Numero de pessoas a reservar"
          />
        </Box>
        <Box w={"100%"}>
          <Box fontSize={".9em"} lineHeight={"1.1"} m={".8em 0 .5em 0"}>
            Pode importar os participantes ou escrever os dados em baixo.
          </Box>

          <InputGroup>
            <Input
              type="file"
              size="md"
              fontSize={".9em"}
              padding={".3em"}
              id="fileInput"
              onChange={importExcel}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm">
                <a href={"static/template.xlsx"}>Template</a>
              </Button>
            </InputRightElement>
          </InputGroup>
          <Box fontSize={".9em"} lineHeight={"1.4"} m={".8em 0 .5em 0"}>
            Em caso de atividades com um maior número de participantes, pode
            descarregar o template apresentado e carregar com as respetivas
            informações{" "}
            <Button h="1.75rem" size="xs">
              <a href={"static/template.xlsx"}>Descarregue o template</a>
            </Button>
          </Box>
        </Box>
        <Box width={"100%"}>
          <label>
            Detalhes <span></span>
          </label>
          {participantsAmount &&
            participantsAmount.map((p, i) => {
              return (
                <Box key={i} m={"0 0 1em 0"} width={"100%"}>
                  <HStack flexDirection="row" spacing=".4em">
                    <Input
                      m={"0"}
                      width={"55%"}
                      onChange={(event) =>
                        updateParticipants(i, "name", event.target.value)
                      }
                      type={"text"}
                      placeholder={"Nome"}
                      value={
                        list && list[i] && list[i]["name"]
                          ? list[i]["name"]
                          : ""
                      }
                    />
                    <Input
                      m={" 0"}
                      width={"45%"}
                      onChange={(event) =>
                        updateParticipants(i, "age", event.target.value)
                      }
                      type={"date"}
                      placeholder={"Data de Nascimento"}
                      value={
                        list && list[i] && list[i]["age"]
                          ? dayjs(list[i]["age"]).format("YYYY-MM-DD")
                          : ""
                      }
                    />
                  </HStack>
                  <HStack flexDirection="row" spacing=".4em">
                    <Input
                      m={".5em 0"}
                      onChange={(event) =>
                        updateParticipants(i, "nif", event.target.value)
                      }
                      type={"text"}
                      placeholder={"nif"}
                      value={
                        list && list[i] && list[i]["nif"] ? list[i]["nif"] : ""
                      }
                    />
                    <Input
                      m={".5em 0"}
                      onChange={(event) =>
                        updateParticipants(i, "comment", event.target.value)
                      }
                      type={"text"}
                      placeholder={"nota"}
                      value={
                        list && list[i] && list[i]["comment"]
                          ? list[i]["comment"]
                          : ""
                      }
                    />
                  </HStack>
                </Box>
              );
            })}
        </Box>
      </VStack>
    </Box>
  );
};

export default Participants;
