import React from "react";
import ImageSide from "../ImageSide/ImageSide";
import "./styles.scss";
import { Flex, Box, VStack } from "@chakra-ui/react";

const ContentSide = (props) => {
  const noImage = props.image === null;
  return (
    <Flex
      height={{ base: "auto", md: "auto" }}
      minHeight={{ base: "auto", md: "100vh" }}
      direction={{ base: "column", md: "row" }}
      overflow={{ base: "visible", md: "auto" }}
    >
      <Flex
        order={{ base: "1", md: "0" }}
        w={{ base: "100%", md: noImage ? "85%" : "60%" }}
        justifyContent={"center"}
        padding={{ base: "60px 0 0 0", md: "260px 0 0 0" }}
        margin={{ base: "0", md: noImage ? "0 0 0 5%" : "" }}
        // overflow={{ base: "visible", md: "auto" }}
        className={"content-side-wrap"}
      >
        <VStack
          spacing={10}
          // justify="center"
          // alignItems={"flex-start"}
          height="100%"
          width={noImage ? "90%" : "80%"}
          maxWidth={noImage ? "1240px" : "500px"}
          mb={"2em"}
        >
          {props.children}
        </VStack>
      </Flex>
      <ImageSide src={props.image || props.altImage} noImage={noImage} />
    </Flex>
  );
};

export default ContentSide;
