import React from "react";
import "./styles.scss";
import { Box } from "@chakra-ui/react";

const Card = (props) => {
  return (
    <Box
      className="cardWrap"
      padding={props.padding}
      margin={props.m}
      {...props}
    >
      {props.children}
    </Box>
  );
};

export default Card;
