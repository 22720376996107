import React from "react";
import { Badge, Box, Flex } from "@chakra-ui/react";
import { MdPayment } from "react-icons/md";
import dayjs from "dayjs";
import Card from "../Card/Card";
import ContentSide from "../ContentSide/ContentSide";
import Button from "../Button/Button";
import { publicURL } from "../../config/config";
import { HiOutlineDocumentAdd } from "react-icons/hi";
import IconBox from "../IconBox/IconBox";

const Thankyou = ({ bookingDetails, groupId }) => {
  const pay = bookingDetails?.booking?.payments;
  return (
    <Box width={"100%"} padding={"0 2em"} mt={"10vh"}>
      <Box
        lineHeight={"1"}
        fontWeight={"bold"}
        color={"#599331"}
        fontSize={"2.5em"}
        mb={".5em"}
      >
        O seu pedido de reserva foi registado com sucesso!
      </Box>

      <Card>
        <Box>
          {pay ? (
            <p>
              Por favor proceda ao pagamento da sua reserva através dos dados a
              baixo.{" "}
              <strong>
                A sua reserva apenas será válida após o pagamento.
              </strong>
            </p>
          ) : (
            <p>
              Verifique o seu e-mail por favor. Em breve a nossa equipa entrará
              em contacto consigo para confirmar a reserva.
            </p>
          )}
        </Box>
      </Card>
      {pay && (
        <>
          {pay?.attr?.expiration_time && (
            <>
              <Flex
                mt={"1.2em"}
                alignItems={"center"}
                justifyContent={"center"}
                fontSize={"1.1em"}
                w={"100%"}
                fontWeight={"bold"}
                gap={4}
              >
                <Box fontSize={".8em"} fontWeight={"bold"} opacity={".7"}>
                  Válido até:
                </Box>{" "}
                <Badge colorScheme="green" fontSize={"lg"}>
                  {dayjs(pay?.attr?.expiration_time).format("DD/MM/YYYY HH:mm")}
                </Badge>
              </Flex>
            </>
          )}
          <Card m={"1em 0 "} p={"1em 2em"} backgroundColor={"#fff"}>
            <Flex
              alignItems={"center"}
              w={"100%"}
              justifyContent={"space-between"}
            >
              <Flex
                alignItems={"center"}
                justifyContent={"center"}
                textAlign={"center"}
                flexDir={"column"}
              >
                <Box
                  background={`url('/static/mb.svg') no-repeat center`}
                  backgroundSize={"contain"}
                  height={"80px"}
                  width={"80px"}
                />
                {/* <MdPayment size={"70"} color="#599331" /> */}
                {/* <Box fontSize={".8em"} fontWeight={"bold"} color="#599331">
                {pay?.status === 0 ? "pendente" : "pago"}
              </Box> */}
                {/* <Box fontSize={".7em"}>
                {dayjs(pay?.createdAt).format("DD/MM/YYYY HH:mm")}
              </Box> */}
              </Flex>
              <Box w={"70%"}>
                <Flex justifyContent={"space-between"} alignItems={"center"}>
                  <Box fontWeight={"bold"} color="#599331" fontSize={"1.2em"}>
                    <Box fontSize={".8rem"} fontWeight={"bold"} opacity={".5"}>
                      Entidade
                    </Box>
                    {pay?.attr?.method?.entity}
                  </Box>
                  <Box fontWeight={"bold"} color="#599331" fontSize={"1.2em"}>
                    <Box fontSize={".8rem"} fontWeight={"bold"} opacity={".5"}>
                      Referencia
                    </Box>
                    {pay?.attr?.method?.reference}
                  </Box>
                  <Box fontWeight={"bold"} color="#599331" fontSize={"1.2em"}>
                    <Box fontSize={".8rem"} fontWeight={"bold"} opacity={".5"}>
                      Valor
                    </Box>{" "}
                    {pay?.amount}€
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Card>
        </>
      )}
      <a href={`${publicURL}/${groupId || ""}${window?.location?.search}`}>
        <Button
          color={"green"}
          variant="outline"
          // onClick={() => (window.location = publicURL)}
        >
          <Box>Escolher outra Atividade</Box>
          <IconBox icon={<HiOutlineDocumentAdd />} />
        </Button>
      </a>
    </Box>
  );
};

export default Thankyou;
