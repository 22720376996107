import React from "react";
import "./styles.scss";
import { Flex, Box, Text, VStack, Code, Grid, Link } from "@chakra-ui/react";

const ImageSide = (props) => {
  return (
    <Box
      w={{ base: "100%", md: props.noImage ? "10%" : "40%" }}
      h={{ base: "40vh", md: "100%" }}
      position={{ base: "relative", md: "fixed" }}
      top={{ base: "auto", md: "0" }}
      right={{ base: "auto", md: "0" }}
      order={{ base: "0", md: "1" }}
      className={"image-side"}
      backgroundImage={props.src ? `url(${props.src})` : ""}
    >
      <Box className={"image-side-pattern"}></Box>
    </Box>
  );
};

export default ImageSide;
