import React, { useState, useEffect } from "react";
import { Box, Flex } from "@chakra-ui/react";
// import { setRemoteCall } from "remote";
import "react-calendar/dist/Calendar.css";
import "./custom.scss";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

/**
 * Time picker
 * @returns
 */

const RenderTimePicker = ({
  props,
  bookedDate,
  bookedEnd,
  value,
  bookingId,
  bookings,
  toogleCalendarView,
  workAvailability,
  availability,
  seats,
  multislots,
}) => {
  let dayData = workAvailability(value);
  const duration = dayData.duration;
  const dayTimeTotalSlots = dayData.totalDayMinutes / dayData.duration || 0;
  const startDate = dayjs(dayData.startDate);
  const startTime = dayjs(value)
    .hour(dayjs(startDate).hour())
    .minute(dayjs(startDate).minute());
  const breaks = dayData.breaks;
  const fullDay = dayData.fullDay;
  const startLunch = dayData.startLunch;
  const endLunch = dayData.endLunch;

  //setnew Times
  /**
   * PURE object timeslots
   */
  let loopDate = startTime;
  const getInitialTimeslotsObject = () => {
    let lunchtime = 60;
    let sL = dayjs(loopDate).format(`YYYY-MM-DDT13:00:00`);
    let eL = dayjs(loopDate).format(`YYYY-MM-DDT14:00:00`);
    if (!fullDay && startLunch && endLunch) {
      sL = dayjs(loopDate).format(`YYYY-MM-DDT${startLunch}:00`);
      eL = dayjs(loopDate).format(`YYYY-MM-DDT${endLunch}:00`);
      lunchtime = dayjs(eL).diff(dayjs(sL), "minute");
    }
    return Array.from(Array(Number(dayTimeTotalSlots.toFixed(0))), (x, i) => {
      let isBreak = false;

      //if lunchtime is between the start and end of the loop
      if (loopDate.isBetween(dayjs(sL), dayjs(eL), "minutes", "()")) {
        console.log("loop date is between lunch");
        //check if its lunchtime + duration is between the start and end of the loop

        loopDate = loopDate.add(duration, "minutes").add(lunchtime, "minutes");
        isBreak = true;
      } else if (
        loopDate
          .add(duration, "minutes")
          .isBetween(dayjs(sL), dayjs(eL), "minutes", "()")
      ) {
        console.log("loop ends inside lunchtime", lunchtime, duration);

        loopDate = dayjs(eL).add(duration, "minutes");
        isBreak = true;
      } else if (
        i > 0 &&
        loopDate
          .add(duration, "minutes")
          .isBetween(dayjs(sL), dayjs(eL), "minutes", "[)")
      ) {
        console.log("loop ends after lunch starts");
        loopDate = dayjs(eL);
        // loopDate = loopDate.add(duration, "minutes").add(lunchtime, "minutes");
        isBreak = true;
      } else if (i > 0) {
        console.log("add", duration, "minutes");
        loopDate = loopDate.add(duration, "minutes");
      }

      return {
        slots: !isBreak ? dayData.slots : 0,
        date: loopDate,
        isBreak: isBreak,
      };
    });
  };

  const [dayTimeSlots, setDayTimeSlots] = useState(getInitialTimeslotsObject());

  /**
   * 1. Work out slots available per time Initial with bookings
   */
  const generateNewTimeslotObjects = () => {
    // console.log(dayTimeSlots);
    let generalOverbooked = 0;
    const processedDS = dayTimeSlots.map((ds) => {
      // console.log(ds);
      const thisDay = ds.date;
      let slots = Number(dayData.slots);
      //workout selected
      let selected = dayjs(bookedDate).isSame(
        thisDay,
        "minutes",
        "[)" //inclusive
      );

      ///allow edit we need to check till end of booking
      if (
        thisDay.isBetween(
          dayjs(bookedDate),
          dayjs(bookedEnd).subtract(1, "minute"),
          "minutes",
          "[)"
        )
      ) {
        selected = true;
      }

      let newDs = { ...ds, slots: slots, selected }; //start new
      bookings.map((booking) => {
        // if we are editing a booking we need to check if its the same booking
        if (bookingId === booking._id) return null;

        if (thisDay.isSame(dayjs(booking.date), "minutes")) {
          //only one booking per slot
          if (!multislots) {
            newDs.slots = 0;
            generalOverbooked = 0;
          } else {
            //allow multiple bookings per slot
            //is this editing the  booking
            if (newDs.slots < booking.seats) {
              newDs.slots = 0;
              generalOverbooked = Math.abs(newDs.slots - booking.seats);
            } else {
              newDs.slots = newDs.slots - booking.seats;
              generalOverbooked = 0;
            }
          }
        }
        //custom end date
        if (
          thisDay.isSame(dayjs(booking.date), "day") &&
          thisDay.isBetween(
            dayjs(booking.date),
            dayjs(booking.dateEnd).subtract(1, "minute"),
            "minutes",
            "[]"
          )
        ) {
          newDs.slots = newDs.slots;
        }
      });

      if (newDs.slots <= generalOverbooked) {
        generalOverbooked = Math.abs(generalOverbooked - newDs.slots);
        newDs.slots = 0;
      } else if (generalOverbooked > 0) {
        newDs.slots = newDs.slots - generalOverbooked;
        generalOverbooked = 0;
      }
      return newDs;
    });
    setDayTimeSlots(processedDS);
    ///WORKOUT IF ITS THIS BOOKING TO ADD IT AS SELECTED
    if (bookingId && dayjs(value).isSame(dayjs(bookedDate), "day")) {
      setSelectDate(bookedDate);
    }
  };
  //bind to change
  useEffect(() => generateNewTimeslotObjects(), [bookings]);

  /**
   * 2. everytime the object is updated let start and end date of the booking
   */

  useEffect(() => {
    let startDate = null;
    let endDate = null;
    dayTimeSlots &&
      dayTimeSlots.map((ds) => {
        if (ds.selected) {
          if (!startDate && ds.startDate) {
            startDate = ds.startDate;
          }
          if (ds.endDate) {
            endDate = ds.endDate;
          }
        }
      });
    if (
      endDate &&
      !endDate.isSame(bookedEnd) &&
      startDate &&
      !startDate.isSame(bookedDate)
    ) {
      //this is where we save the new date
      props.onChange("setDates", {
        date: startDate.toDate(),
        dateEnd: endDate.toDate(),
      });
    }
  }, [dayTimeSlots]);

  /**
   * 3. We need to set end and start date so that bookings are updated automatically
   * @param {*} thisDateTime
   */
  const setSelectDate = (thisDateTime) => {
    let overbooked = 0;
    //workout availability
    const processedDS = dayTimeSlots.map((ds) => {
      //0 Lets firs process the booking data
      const thisDay = ds.date;
      let slots = Number(dayData.slots);
      const selected = dayjs(thisDateTime).isSame(
        ds.date,
        "minutes",
        "[]" //inclusive
      );
      let newDs = {
        ...ds,
        slots: slots,
        selected,
        startDate: thisDateTime,
        endDate: dayjs(thisDateTime).add(duration, "minutes"),
      }; //start new

      bookings.map((booking) => {
        if (bookingId === booking._id) return null; //dont process if its the same

        if (thisDay.isSame(dayjs(booking.date), "day")) {
          if (
            thisDay.isBetween(
              dayjs(booking.date),
              dayjs(booking.dateEnd || booking.date).subtract(1, "minute"),
              "minutes",
              "[]" //inclusive
            )
          ) {
            if (!multislots) {
              //only one booking per slot
              newDs.slots = 0;
            } else {
              //allow multiple bookings per slot
              newDs.slots = newDs.slots - booking.seats;
            }
          }
        }
      });

      //1 check if we are in the date for processing
      if (selected) {
        //1.1 check we have enough slots
        if (newDs.slots > 0 && newDs.slots < Number(props.seats)) {
          overbooked = Math.abs(newDs.slots - Number(props.seats));
          newDs.selected = true;
          newDs.slots = 0;
        } else if (newDs.slots > 0 && newDs.slots > Number(props.seats)) {
          //1.2 we are not overbooked just remove seats
          newDs.selected = true;
          newDs.slots = newDs.slots - Number(props.seats);
          overbooked = 0;
          newDs.endDate = newDs.date.add(duration, "minutes");
        }
      }

      //2 we are outside the date lets check if we are overbooked
      if (overbooked > 0) {
        // 2.1 check is we are still overbooked
        if (newDs.slots > 0 && overbooked && newDs.slots <= overbooked) {
          overbooked = Math.abs(newDs.slots - Number(overbooked));
          newDs.selected = true;
          newDs.slots = 0;
        } else if (newDs.slots > 0 && overbooked < newDs.slots) {
          // 2.2 overbook ennewDs here
          newDs.selected = true;
          newDs.slots = newDs.slots - overbooked;
          overbooked = 0;
          newDs.endDate = newDs.date.add(duration, "minutes");
        }
      }
      return newDs;
    });
    setDayTimeSlots(processedDS);
    // onChange("setDates", {})
  };

  try {
    //work dates
    const thisDate = dayjs(value).locale("pt");
    //setnew Times

    const endDate = dayjs(dayData.endDate).subtract(1, "minute");
    const endTime = thisDate.hour(endDate.hour()).minute(endDate.minute());

    //word day defaults
    const slots = dayData.slots;
    const totalDaySlots = dayData.totalDaySlots;

    //iterationDate
    return (
      <Box>
        <Flex
          textAlign={"center"}
          justifyContent={"center"}
          fontSize={".8em"}
          mb={"1em"}
          alignItems={"center"}
          textTransform={"capitalize"}
        >
          {/* <Box
            fontSize={"1.2em"}
            lineHeight={"1"}
            fontWeight={"bold"}
            mr={".3em"}
            textAlign={"center"}
          >
            {totalDaySlots}
            <Box
              fontSize={".5em"}
              lineHeight={"1"}
              color={"#888"}
              textAlign={"center"}
            >
              lugares
            </Box>
          </Box> */}

          {thisDate && (
            <Box
              fontSize={"1.5em"}
              lineHeight={"1"}
              fontWeight={"bold"}
              color={"#888"}
            >
              {thisDate.format("DD MMMM YYYY")}
            </Box>
          )}
        </Flex>
        <Flex
          w={"100%"}
          alignItems={"center"}
          flexWrap={"wrap"}
          justifyContent={"center"}
        >
          {dayTimeSlots.map((s, i) => {
            const thisDateTime = dayjs(s.date);
            const selected = s.selected;
            return (
              <Box key={i}>
                {/* {s.isBreak && (
                  <Flex
                    w={"100%"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Box
                      fontSize={".8rem"}
                      lineHeight={"1"}
                      color={"#fff"}
                      textAlign={"center"}
                      padding={"1em 2em"}
                      cursor={"pointer"}
                      background={"#000"}
                      borderRadius={"8px"}
                      _hover={{ background: "#000" }}
                      margin={".5em 0 0.5em .8em"}
                    >
                      Pausa de {breaks} min
                    </Box>
                  </Flex>
                )} */}
                <Box>
                  <Box
                    fontSize={"1rem"}
                    lineHeight={"1"}
                    color={selected ? "#fff" : "#888"}
                    textAlign={"center"}
                    padding={".8em"}
                    background={selected ? "#000" : "#efefef"}
                    borderRadius={selected ? "8px" : "8px 8px 0 0"}
                    margin={".5em .5em 0 .5em"}
                    cursor={"pointer"}
                    fontWeight={"bold"}
                    // onClick={() => setSelectDate(thisDateTime)}
                  >
                    {thisDateTime.format("HH:mm")}
                  </Box>
                  {/* <Box
                  fontSize={".8rem"}
                  lineHeight={"1"}
                  color={selected ? "#fff" : "#888"}
                  textAlign={"center"}
                  padding={"1.2em 2em"}
                  background={selected ? "#000" : "#efefef"}
                  borderRadius={"8px"}
                  fontWeight={"bold"}
                  margin={".5em 0"}
                  cursor={"pointer"}
                  // onClick={() => props.onChange("date", thisDateTime)}
                >
                  {s.slots} Lugares Disponiveis
                </Box> */}
                  {!selected && s.slots > 0 && s.slots >= props.seats && (
                    <Box
                      fontSize={".8rem"}
                      lineHeight={"1"}
                      color={"#fff"}
                      textAlign={"center"}
                      padding={".4em"}
                      cursor={"pointer"}
                      background={"#4B8A2D"}
                      borderRadius={"0 0 8px 8px"}
                      _hover={{ background: "#000" }}
                      margin={"0 .5em .5em .5em"}
                      onClick={() => setSelectDate(thisDateTime)}
                    >
                      Escolher
                    </Box>
                  )}
                </Box>
              </Box>
            );
          })}
        </Flex>
        <Box
          fontSize={".8rem"}
          lineHeight={"1"}
          color={"#fff"}
          textAlign={"center"}
          padding={"1em"}
          cursor={"pointer"}
          background={"#777"}
          borderRadius={"8px"}
          margin={".5em 0"}
          onClick={() => {
            toogleCalendarView(false);
          }}
        >
          ← Escolher outro dia
        </Box>
      </Box>
    );
  } catch (e) {
    console.log(e);
    return (
      <Box
        fontSize={"1rem"}
        lineHeight={"1"}
        color={"#fff"}
        textAlign={"center"}
        padding={"1em"}
        cursor={"pointer"}
        background={"#000"}
        borderRadius={"8px"}
        margin={".5em 0"}
        onClick={() => {
          toogleCalendarView(false);
        }}
      >
        Voltar
      </Box>
    );
  }
};

export default RenderTimePicker;
